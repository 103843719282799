import Articles from "../../components/articles";
import {Box, Typography} from "@mui/material";
import React from "react";

export default function ArticlesPage() {
    return(
        <Box>
            <Articles/>
        </Box>
    )
}